import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

const {
  REACT_APP_FB_ACCOUNT_APIKEY,
  REACT_APP_FB_ACCOUNT_PROJECT_ID,
  REACT_APP_FB_ACCOUNT_SENDER_ID
} = process.env;

const config = {
  apiKey: REACT_APP_FB_ACCOUNT_APIKEY,
  authDomain: `${REACT_APP_FB_ACCOUNT_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${REACT_APP_FB_ACCOUNT_PROJECT_ID}.firebaseio.com`,
  projectId: `${REACT_APP_FB_ACCOUNT_PROJECT_ID}`,
  storageBucket: `${REACT_APP_FB_ACCOUNT_PROJECT_ID}.appspot.com`,
  messagingSenderId: REACT_APP_FB_ACCOUNT_SENDER_ID
};

const firebaseApp = firebase.initializeApp(config);

export default firebaseApp;
