export default {
  locale: 'en',
  language_thailand: 'ภาษาไทย',
  language_english: 'English',
  change_language: 'เปลี่ยนภาษา',
  hello: 'HELLO!',
  pointspot: 'Pointspot',
  continue_with_google: 'Continue with Google',
  continue_with_facebook: 'Continue with Facebook',
  accept_terms: 'By signing in to system you agree to all the',
  accept_terms_create: 'By creating account you agree to all the',
  terms_conditions: 'Terms & Conditions',
  privacy_policy: 'Privacy Policy',
  signup: 'Create new Pointspot business account',
  signin: 'Sign in',
  signout: 'Sign out',
  email: 'Email',
  change_email: 'Change email',
  user_information: 'User information',
  password: 'Password',
  reset_password: 'Reset password',
  forget_password: 'Forget password',
  singin_with_pointspot_account: 'Sign in with your Pointspot account',
  email_or_password_is_incorrect: 'Email or Password is incorrect.',
  congratulations: 'Congratulations',
  email_verified: 'Your email has been verified.',
  firstname: 'First name',
  lastname: 'Last name',
  contact_phone: 'Mobile phone number',
  set_password: 'New password',
  confirm_password: 'Confirm password again',
  submit: 'Submit',
  confirm: 'Confirm',
  back: 'Back',
  or: 'OR',
  note: 'Note',
  email_incorrect: 'Email is incorrect',
  email_already_in_use: 'Email is already in use',
  weak_password: 'Password must be at least 6 characters',
  password_not_match: 'Password not match',
  logout: 'Logout',
  check_email: 'Please check email',
  crop: 'Crop',
  cancel: 'Cancel',
  save: 'Save',
  create: 'Create',
  next: 'Next',
  and: 'and',
  business: 'Business',
  update: 'Update',
  create_business: 'Create business',
  credit_card: 'Credit Card',
  debit_card: 'Debit Card',
  edit: 'Edit',
  edit_data: 'Edit',
  product: 'Product',
  notification: 'Notification',
  confirm_logout: 'Are you sure you want to sign out?',
  retry: 'Retry',
  expired: 'Expired',
  select: 'Select',

  //Alert Message
  cannot_completed_request: 'Cannot completed your request,\r\nplease try again later.',
  edit_image_success: 'Edit image profile success',
  reset_password_success: 'Reset password success',
  add_creditcard_success: 'Add card success',
  delete_creditcard_success: 'Delete card success',
  default_creditcard_success: 'Set up card success',
  edit_invoice_address_success: 'Edit Invoice / Receipt address success',
  foreign_pay_detail: 'In case pay for the service with a debit card, please contact the bank to approve paying in foreign country.',
  card_verification_remark: 'Pointspot will make a one-time test charge of 1 Baht when adding your payment card. The transaction will be voided after charged successfully.',
  file_types : 'Support .jpg, .jpeg and .png',
  no_business_supporting_credit_card: 'No businesses supporting payment by credit/debit cards were found.',

  // Proflie
  profile: 'Profile',
  wallet_id: 'Wallet ID',
  check_email_for_reset_password: 'Link for reset your password is send to',
  pointspot_account: 'Pointspot Account',
  new_password: 'New password',
  password_changed: 'Password changed',
  signin_with_new: 'You can now sign in with your new password',
  resend_email: 'Resend email',
  dont_receive_email: 'Didn\'t receive email ?',
  please_verify_email: 'Please verify your email address',
  please_check_email: `Please check your email`,
  please_check_email2: `And confirm by email that Pointspot sent to`,
  edit_profile: 'Edit Profile',
  edit_password: 'Edit Password',
  select_photo: 'Select Photo',
  name_surname: 'Name & Surname',
  limit_characters: 'Limit 30 Characters',
  confirm_email: 'Are you want to reset password from this email ?',
  confirm_change_email: 'Are you want to change email for this account ?',
  send_change_email_success: 'The request to change your email has been successful.\nPlease check your new email for verification.',
  require_email_reset: 'Reset password for this email',
  edit_profile_success: 'Edit profile success',
  no_results_found: 'No results found',
  order_by: 'Order by',
  choose: 'Choose',

  // Package
  package: 'Package',
  default_credit_card: 'Being used',
  set_default_credit_card: 'Use this card',
  you_want_delete_credit_card: 'Do you want to delete card ?',
  you_want_set_default_credit_card: 'Do you want to use this card ?',
  add_card: 'add card',
  add_credit_card: 'Add Credit card / Debit card',
  please_add_credit_card: 'Please add Credit card / Debit card',
  credit_card_number: 'Card Number',
  when_doing: 'When doing',
  you_understand_and_accept: 'You understand and accept',
  cardholder_name: 'Cardholder Name',
  expiration_month: 'Expiration month',
  expiration_year: 'Expiration year',
  delete_credit_card: 'Delete card',

  // Paymant Method
  payment_method: 'Payment Method',
  terms_payment: 'Payment terms',
  terms_payment_list: [{
    title: '1. Payment method',
    description: 'Payment for Pointspot service will charge via credit card online, The system will summarize the expense for each month on the last day of the month and debiting from the card that has been set up in the system. In the case that the card payment system cannot be deduct money because the card has expired or the credit limit is not enough or others, service may be unavailable until the outstanding payments.'
  }, {
    title: '2. Pointspot service provider',
    description: 'The company that provides Pointspot service is Readyplanet Public Company Limited., which is registered and located in 51 MAJOR TOWER RAMA 9 - RAMKUMHANG Building 17th floor, room 1701 - 1706, Ramkhamhaeng Road, Hua Mak Subdistrict, Bang Kapi District, Bangkok 10240. The service charge is include VAT'
  }, {
    title: '3. Payment security system',
    description: 'Pointspot uses online payment systems from 2c2p, a global online payment service provider. Credit card information is not stored in the Pointspot system but it is maintained at the payment service company. For security reasons'
  }],
  terms_accept: "Accept",
  invoice_payment_method: 'Payment Method',

  // Invoice Receipt
  invoice: 'Invoice',
  receipt: 'Receipt',
  status: 'Status',
  download: 'Download',
  balance: 'Balance',
  processing: 'Processing',
  paid: 'Paid',
  failed: 'Failed',
  pay: 'Pay',
  list_invoice_receipt: 'Invoice / Receipt list',
  usages_receipt: 'Usage history report',
  usage: 'Usage',
  usage_summary: 'Usage summary of',
  address_invoice_receipt: 'Invoice / Receipt address',
  retry_charge_confirm: 'Please confirm payment.',
  payment_success: 'Payment successful',
  billing_detail: 'Billing Detail',
  billing_unit_credit: 'Credit per unit',
  usage_detail: 'Usage Detail',
  data_on: 'Data as at',

  // Month
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  // Address 
  company_name: 'Company name / Legal entity',
  phone: 'Phone',
  address: 'Address',
  address_number: 'Number',
  village_number: 'Village No.',
  village: 'Village',
  lane: 'Lane',
  road: 'Road',
  district: 'District',
  city: 'City',
  state: 'State',
  postal_code: 'Postal Code',
  country: 'Country',
  please_fill_detail_english: 'Please fill up the details only in English',
  please_fill_information: 'Please fill in the information',
  please_fill_information_english: 'Please fill in English',
  please_fill_information_number: 'Please fill in Number',
  please_fill_information_format_phone: 'Please fill in Phone',
  tax_id: 'Taxpayer Identification No. / Identification No.',

  // Credit Balance
  credit: 'Credit',
  prepaid_credit: 'Pointspot credit for package',
  annual_credit: 'Pointspot credit for promotional transactions',
  credit_balance: 'Credit balance',
  number_rank: 'No.',
  of: 'of',
  activities: 'Activities',
  credit_list: 'Credit list',
  debit_action: 'Debit',
  credit_action: 'Credit',
  balance_remain: 'Balance',
  balance_transaction: 'Credit balance transaction',
  no_balance_transaction: 'No credit balance transaction',
  balance_status: {
    adjustment: 'Adjust credit',
    applied_to_invoice: 'Apply to invoice',
    invoice_too_small: 'Credit overdue',
    usages_on: 'Usages on',
  },

  // 2c2p
  card_no_require: "Card number is required",
  card_no_invalid: "Card number is invalid",
  exp_month_require: "Expiry month is required",
  exp_month_2_digit: "Expiry month must be two numbers",
  exp_month_invalid: "Expiry month is invalid",
  exp_year_require: "Expiry year is required",
  exp_year_4_digit: "Expiry year must be four numbers",
  card_expired: "Card already expired",
  cvv_invalid: "CVV is invalid",
  add_card_error: "Transaction failed, please try again later.",
  add_card_error_cancelled: "Transaction failed, transaction is cancelled.",
  add_card_error_invalid_number: "Transaction failed, invalid card number.",
  add_card_error_owner_cancel: "Transaction failed, customer cancellation.",
  add_card_error_bank_unsupport: "Transaction failed, bank not supported.",
  add_card_error_expired_card: "Transaction failed, expired card.",
  add_card_error_restricted_card: "Transaction failed, restricted card.",
  add_card_error_lost_card: "Transaction failed, lost card marked.",
  add_card_error_stolen_card: "Transaction failed, stolen card marked.",
  add_card_error_insufficient_funds: "Transaction failed, insufficient funds.",
  add_card_error_exceed_withdrawal_limit: "Transaction failed, Exceeds Withdrawal Frequency Limit.",

  //BillingDetailModal
  close: "Close",
  member_usage_description: 'Member means the user who received the member card or the registered user with reward point.',
  transaction_usage_description: 'Transaction means the amount of activity from admin to client about point (sending, pulling), sending coupon or create member card.',
  transaction_yearly_usage_description: '"Basic Transaction" include activities such as sending or pulling points, and creating member cards.',
  transaction_yearly_credit_usage_description: '"Credit usage" include activities such as sending broadcasts, issuing promotional coupons, issuing receipt coupons, sending gift vouchers, and transactions that exceed the package limit.',
  notification_usage_description: 'Only the overage notification message is charged.',
  remark: "Remark",
  current_package: "Current package",
  package_and_charge: 'Package and Charge',
  bath:'THB',
  members: 'Members',
  member_package: 'Members',
  transactions: 'Transactions',
  basic_transactions: 'Basic Transactions',
  promotional_transactions: 'Promotional Transactions',
  monthly_free_credits: 'Monthly Free Credit',
  add_ons_credit: 'Add-ons Credit',
  premium_id: 'Premium ID',
  product_level_points: 'Product-level points',
  sender_name: 'Sender name',
  additional: 'Additional features',
  sub_total:'Sub total',
  vat:'VAT',
  annual_fee: 'Annual fee',
  net_total:'Net Total',
  broadcast_message :'Broadcast message',
  add_ons: 'Add-ons',
  during_billing_cycle:'The current balance during billing cycle',
  payment_method_1:'Payment Method',
  items: 'Items',
  monthly_fee:'Monthly fee',
  quantity:'Quantity',
  unit_price:'Unit Price',
  amount: 'Amount',
  create_date: 'Create date',
  overage: 'Overage',
  broadcast_billing_credit: 'Credits Usage',
  remain: 'Balance',
  free_credit: 'Free Credit',
  credit_use: 'Used credit',
  total_credit_use: 'Total credits used',
  view_details: 'view details',
  view_details_less: 'view less',
  overage_items: {
    overage_basic_transaction: 'Overage Basic Transactions',
    additional_transaction: 'Transactions',
    additional_sms: 'Notification - SMS',
    additional_email: 'Notification - EMAIL',
    additional_shappy: 'Notification - SHAPPY',
    additional_line_noti: 'Notification - LINE',
    additional_sms_broadcast: 'Broadcast - SMS',
    additional_email_broadcast: 'Broadcast - EMAIL',
    additional_line_broadcast: 'Broadcast - LINE',
    additional_shappy_broadcast: 'Broadcast - SHAPPY',
    additional_member: 'Members',
    additional_sms_inter: 'International - SMS',
    advance_apis: 'Advance APIs',
    additional_coupon_transaction: 'Coupon/Gift voucher',
    additional_coupon_sms: 'Coupon/Gift voucher - SMS',
    additional_coupon_line: 'Coupon/Gift voucher - LINE',
    additional_coupon_email: 'Coupon/Gift voucher - EMAIL',
    additional_coupon_shappy: 'Coupon/Gift voucher - SHAPPY',
    not_send_notification: 'Send without notification',
    broadcast: 'Broadcast',
    notification: 'Notification',
    additional_sms_collect_points: 'Points collect requests rejected notifications - SMS',
    additional_line_collect_points: 'Points collect requests rejected notifications - LINE',
    additional_email_collect_points: 'Points collect requests rejected notifications - EMAIL',
    additional_shappy_collect_points: 'Points collect requests rejected notifications - SHAPPY',
    points_collect_requests_rejected: 'Points collect requests rejected notifications.',
    advance_points_expiration_notifications: 'Advance points expiration notifications.',
    additional_sms_points_expiration: 'Advance points expiration notifications - SMS',
    additional_line_points_expiration: 'Advance points expiration notifications - LINE',
    additional_email_points_expiration: 'Advance points expiration notifications - EMAIL',
    additional_shappy_points_expiration: 'Advance points expiration notifications - SHAPPY',
  },
  subscription_coupons: {
    internal_account: "Internal Account",
    system_change_package: "System Change Package",
    special_discount: "Special Discount",
    first_month_25_discount: "First month upgrade 25% discount",
    first_month_50_discount: "First month upgrade 50% discount",
    first_month_75_discount: "First month upgrade 75% discount",
    covid_discount: "Covid discount",
    trial_package: "Trial package"
  },
  charge_automatically: 'Charge automatically',
  send_invoice: 'Send invoice',
  namePackage: {
    "ps-f32": "F32",
    "ps-free-trial": "Free-trial",
    "ps-max": "Max",
    "ps-platinum": "Platinum",
    "ps-gold": "Gold",
    "ps-premium": "Premium",
    "ps-shappy": "Shappy",
    "ps-pro": "Pro",
    "ps-free": "Free",
    "ps-test": "Test",
    "ps-test-annual": "Test",
    "ps-test-annual-2": "Test",
    "premium-1k": "Premium",
    "ps-enterprise-10k": "Enterprise",
    "ps-enterprise-42k": "Enterprise",
    "ps-starter": 'Starter',
    "ps-professional": 'Professional',
    "ps-enterprise": 'Enterprise',
    "ps-pro-a": "Pro-A",
    "ps-premium-a": "Premium-A",
    "ps-f200": "F200",
  },
  usage_title: 'Usage',
  overage_transaction: 'Overage Transactions',
  points: 'Points',
  coupon: 'Coupon',
  broadcast: 'Broadcast',
  member_card: 'Member card',
  noti_expire_point: 'Points Expiration Notification',
  noti_expire_member_card: 'Member Card Expiration Notification',
  reject_collect_points: 'Collect Points Notification',
  sms: 'SMS',
  line: 'LINE',
  email: 'Email',
}